import Store from "@/store";
import {menus} from "@/config/menus";
import {uploadWebTokenApi,blacklistListApi,customerListApi} from "@/api";
import {ElMessageBox} from "element-plus";
import axios from 'axios';
import { elMsg } from "./message";

export const randomString = (len = 16) => {
    const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    let str = '';
    for (let i = 0; i < len; i++) {
        str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return str;
}
export const getOssToken = () => {
    return new Promise((resolve, reject) => {
        uploadWebTokenApi().then(res => {
            if (res.data) {
                Store.commit("oss/setToken", res.data);
                resolve();
            }
        }).catch(() => {
            reject();
        });
    })

}

// 设置menu
export const setMenuList = (data) => {
    menus.forEach(item => {
        const ruleItem = data.find(i => i.route === item.path);
        if (ruleItem) {
            item.sort = ruleItem.sort;
        }
    })
    const menusSort = menus.sort((a, b) => b.sort - a.sort);
    console.log('menusSort', menusSort);
	const menusList = [];
	const homePage = data.filter(i => i.route === "/home");
	if (homePage?.length) {
		menusList.push({
			...menusSort[0],
			title: homePage[0].name,
			name: menusSort[0].name,
			path: homePage[0].route,
		})
	}
	menusSort.forEach(item => {
		const children = data.filter(i => i.module_name === item.title);
        children.forEach(child => {
            const childlist = data.filter(i => i.module_name === child.name && i.type == 1);
            if (data.some(i => i.route === child.route) && childlist.length) {
                child.children = childlist.map((i, j) => {
                    return {
                        title: i.name,
                        two_type: i.two_type,
                        name: child.route.slice(1) + j,
                        path: i.route,
                    }
                })
            }
        })
		if (data.some(i => i.route === item.path) && children.length) {
			menusList.push({
				...item,
				children: children.map((i, j) => {
					return {
						title: i.name,
                        two_type: i.two_type,
						name: item.path.slice(1) + j,
						path: i.route,
                        children: i.children || [],
					}
				})
			})
		}
	})
    console.log('menusList', menusList);
	return menusList
}

// 设置router
export const setRouterMenuList = (data) => {
	let routerList = []
	// 拿到全部菜单类型
	let menuList = data.filter((item) => item.type == '1')
	menuList.forEach((item) => {
		if (item.module_name == 'top') {
			routerList.push({
				id: item.id,
				name: item.name,
				url: item.route == '/' ? null : item.route,
				openStyle: 0,
				icon: item.icon,
				children: []
			})
		}
	})
	menuList.forEach((item) => {
		let itemIndex = routerList.findIndex((f) => f.name == item.module_name)
		if (itemIndex != -1) {
			routerList[itemIndex].children.push({
				id: item.id,
				name: item.name,
				url: item.route,
				openStyle: 0,
				icon: item.icon,
                children: data.filter(i => i.module_name === item.name && i.type == 1),
			})
		}
	})
	// 如果是一级菜单但是没有子菜单的就不显示
	let noneIndex = []
	routerList.forEach((item,i) => {
		if(!item.url && item.children.length == 0){
			noneIndex.push(i)
		}
	});
	noneIndex.forEach((item) => {
		routerList.splice(item,1)
	})
	// // .console.log(routerList);
	return routerList
}
// 判断是否有权限
export const hasRule = (rule) => {
	// return true
	// console.log(rule);
	if(!rule){
		return false
	}else{
		return Store.state.system.menuListAll.some((item) => rule.split(",").includes(item.route))
	}
}
// 菜单权限过滤规则
export const filterMenu = (data) => {
	return data.filter((i)=>i.name!='业绩情况'&&i.module_name!='业绩情况'&&i.name!='订单应收'&&i.name!='订单收款单'&&i.name!='订单确认收款'&&i.name!='租金统计')
}
// 清除为空''的对象属性
export const clearEmptyProp = (obj) => {
	for (let key in obj) {
		if (obj[key] === '' || obj[key] === null) {
			delete obj[key]
		} else if (Array.isArray(obj[key])) {
			obj[key].forEach((item) => {
				clearEmptyProp(item)
			});
		}
	}
	return obj
}
// 对象属性是否都为空值
export const isEmptyProp = (obj) => {
	let isEmpty = true
	for (let key in obj) {
		if (obj[key] != null && obj[key] != "") {
			isEmpty = false
		}
	}
	return isEmpty
}
// 根据手机号或者身份证号查询当前是否黑名单用户
export const searchHmd = (phone, mini_phone, idCard) => {
	return new Promise(async (resolve) => {
	  let resData = {
		phone: {data: null},
		mini_phone: {data: null},
		idCard: {data: null},
	  }
	  if (phone) {
		resData.phone = await blacklistListApi({search: phone})
	  }
	  if (mini_phone) {
		resData.mini_phone = await blacklistListApi({search: mini_phone})
	  }
	  if (idCard) {
		resData.idCard = await blacklistListApi({search: idCard})
	  }
	  console.log("resData+++++++++", resData);
	  if (!resData.phone.data && !resData.mini_phone.data && !resData.idCard.data) {
		resolve(true)
	  } else if (resData.phone?.data || resData.mini_phone?.data || resData.idCard?.data) {
		ElMessageBox.confirm(`该用户是黑名单客户`, '提示', {
			confirmButtonText: '继续',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(async () => {
			resolve(true)
		}).catch(() => {
			resolve(false)
		});
	  } else {
		resolve(true)
	  }
	})
  }
  // 根据手机号或者身份证号查询当前黑名单ID
  export const searchHmdId = (phone, idCard) => {
	return new Promise(async (resolve) => {
	  let resData = {
		phone: null,
		idCard: null
	  }
	  if (phone) {
		resData.phone = await blacklistListApi({search: phone})
	  }
	  if (idCard) {
		resData.idCard = await blacklistListApi({search: idCard})
	  }
	  if (!resData.phone && !resData.idCard) {
		resolve('')
	  } else if (
		((resData.phone && !resData.phone.data) && (resData.idCard && !resData.idCard.data)) || 
		((resData.phone && !resData.phone.data) && !resData.idCard) || 
		((resData.idCard && !resData.idCard.data) && !resData.phone)
	  ) {
		resolve('')
	  } else {
		if (resData.phone && resData.phone.data) {
		  resolve(resData.phone.data.id)
		} else if (resData.idCard && resData.idCard.data) {
		  resolve(resData.idCard.data.id)
		}
	  }
	})
  }
  // 根据手机号、身份证号查询当前是否存在客户
  export const searchUserByPhone = (phone, idCard) => {
	return new Promise(async (resolve) => {
	  let resData = {
		phone: null,
		idCard: null
	  }
	  if (phone) {
		resData.phone = await customerListApi({search: phone})
	  }
	  if (idCard) {
		resData.idCard = await customerListApi({search: idCard})
	  }
	  console.log(resData);
	  if (!resData.phone && !resData.idCard) {
		resolve(false)
	  } else if (
		((resData.phone && !resData.phone.data) && (resData.idCard && !resData.idCard.data)) || 
		((resData.phone && !resData.phone.data) && !resData.idCard) || 
		((resData.idCard && !resData.idCard.data) && !resData.phone)
	  ) {
		resolve(false)
	  } else {
		if (resData.phone && resData.phone.data && resData.phone.data.length) {
		  resolve(resData.phone.data[0])
		} else if (resData.idCard && resData.idCard.data && resData.idCard.data.length) {
		  resolve(resData.idCard.data[0])
		} else {
		  resolve(false)
		}
	  }
	})
  }
  //获取url参数
export const getUrlParam = (key) => {
	const url = location.href
	// 获取 hash 值，不包含 '#' 号
	const hash = url.substring(url.indexOf("#") + 1)
	// 查找 '?' 号所在的索引
	const searchIndex = hash.indexOf("?")
	// '?' 号后面接的是索引参数，如果找到则+1，去除'?' 号
	const search = searchIndex !== -1 ? hash.substring(searchIndex + 1) : ""
	// 把搜索参数字符串提过URLSearchParams转成对象形式
	const usp = new URLSearchParams(search)
	// 通过URLSearchParams自带的get方法，查询键所对应的值
	return usp.get(key)
}
  
  //获取url参数(简易版只有一个参数且在最后)
export const getUrlParamSelp = (key) => {
	const url = location.href
	return url.slice(url.indexOf(key+"=")+key.length+1)
}
  //获取两个时间段之间的天数
export const setDayTime = (start,end) => {
	if(!start){
		return 0
	}else{
		let startFull = start.replace(/-/g,'/');
        let endFull = isNaN(end) ? new Date(end.replace(/-/g,'/')).getTime() : Number(end);
		let day = Math.ceil((new Date(startFull).getTime() - endFull)/86400000)
		return day<=0?0:day
	}
}
export const formatNumber = n => {
    n = n.toString();
    return n[1] ? n : `0${n}`;
}
// 时间戳类型转为送送车时间
export const formatTimeCar = (date) => {
    let times = new Date(date);
    const year = times.getFullYear();
    const month = times.getMonth() + 1;
    const day = times.getDate();
    const hour = times.getHours();
    const minute = times.getMinutes();
    return `${[year, month, day].map(formatNumber).join('-')} ${[hour, minute].map(formatNumber).join(':')}`;
}
// 时间戳类型转
export const timeResetDate = (value, nohour, hen = '-') => {
    let time = value ? new Date(value) : new Date();
    let year = time.getFullYear()
    let month = time.getMonth() + 1
    let date = time.getDate()
    let hours = time.getHours()
    let minute = time.getMinutes()
    let second = time.getSeconds()

    if (month < 10) {
      month = '0' + month
    }
    if (date < 10) {
      date = '0' + date
    }
    if (hours < 10) {
      hours = '0' + hours
    }
    if (minute < 10) {
      minute = '0' + minute
    }
    if (second < 10) {
      second = '0' + second
    }
    if (nohour) {
      return year + hen + month + hen + date
    } else {
      return year + hen + month + hen + date + ' ' + hours + ':' + minute + ':' + second
    }
}
// 数字输入框不能输入负数
export const notNoNumber = (obj,prop,e,min = 0,max = 999999999,callback) => {
	if(Number(e) < Number(min) || isNaN(e)){
		obj[prop] = Number(min)
	}else if(Number(e) > Number(max)){
		obj[prop] = Number(max)
	}
	if(typeof callback === 'function'){
		callback()
	}
}
// 只能输入手机号
export const phoneNumber = (obj,prop,e,len = 11, callback) => {
	if(isNaN(e)){
		obj[prop] = "";
	} else if(e.includes(".")){
		obj[prop] = obj[prop].replace(/\./gi, "");
	} else if(e.length > len){
		obj[prop] = obj[prop].slice(0, len)
	}
	if(typeof callback === 'function'){
		callback()
	}
}
/**
 * [getFileType 下载文档对应的类型]
 * @param  {[type]} type [description]
 * @param  {[type]} id   [description]
 * @return {[type]}      [description]
 */
export function getFileType(id) {
	let arr = [
		{ type: "doc", application: "application/msword" },
		{
			type: "docx",
			application:
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
		},
		{ type: "dot", application: "application/msword" },
		{
			type: "dotx",
			application:
				"application/vnd.openxmlformats-officedocument.wordprocessingml.template"
		},
		{ type: "xls", application: "application/vnd.ms-excel" },
		{
			type: "xlsx",
			application:
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
		},
		{ type: "ppt", application: "application/vnd.ms-powerpoint" },
		{
			type: "pptx",
			application:
				"application/vnd.openxmlformats-officedocument.presentationml.presentation"
		},
		{ type: "pdf", application: "application/pdf" },
		{ type: "txt", application: "text/plain" },
		{ type: "gif", application: "image/gif" },
		{ type: "jpeg", application: "image/jpeg" },
		{ type: "jpg", application: "image/jpeg" },
		{ type: "png", application: "image/png" },
		{ type: "css", application: "text/css" },
		{ type: "html", application: "text/html" },
		{ type: "htm", application: "text/html" },
		{ type: "xsl", application: "text/xml" },
		{ type: "xml", application: "text/xml" },
		{ type: "mpeg", application: "video/mpeg" },
		{ type: "mpg", application: "video/mpeg" },
		{ type: "avi", application: "video/x-msvideo" },
		{ type: "movie", application: "video/x-sgi-movie" },
		{ type: "bin", application: "application/octet-stream" },
		{ type: "exe", application: "application/octet-stream" },
		{ type: "so", application: "application/octet-stream" },
		{ type: "dll", application: "application/octet-stream" },
		{ type: "ai", application: "application/postscript" },
		{ type: "dir", application: "application/x-director" },
		{ type: "js", application: "application/x-javascript" },
		{ type: "swf", application: "application/x-shockwave-flash" },
		{ type: "xhtml", application: "application/xhtml+xml" },
		{ type: "xht", application: "application/xhtml+xml" },
		{ type: "zip", application: "application/zip" },
		{ type: "mid", application: "audio/midi" },
		{ type: "midi", application: "audio/midi" },
		{ type: "mp3", application: "audio/mpeg" },
		{ type: "rm", application: "audio/x-pn-realaudio" },
		{ type: "rpm", application: "audio/x-pn-realaudio-plugin" },
		{ type: "wav", application: "audio/x-wav" },
		{ type: "bmp", application: "image/bmp" }
	];
	return findArrayReturnValue(arr, "type", id, "application");
}

/**
 * @param arr 查找数组
 * @param property 查找属性
 * @param value 查找值
 * @des:查找到值后返回其值
 */
export const findArrayReturnValue = (arr, property, value, findKey) => {
	let len, findValue;
	if (!Array.isArray(arr)) {
		return;
	}
	len = arr.length;
	for (var i = 0; i < len; i++) {
		if (
			arr[i].hasOwnProperty(property) &&
			arr[i][property] == value &&
			arr[i].hasOwnProperty(findKey)
		) {
			findValue = arr[i][findKey];
			break;
		}
	}
	return findValue;
};
/** download All*/
export const downMethod = (data) => {
	return new Promise((resolve) => {
		axios({
			method: data.method,
			url: process.env.VUE_APP_BASE_URL + data.url,
			params: clearEmptyProp(data.params),
			headers: {
				'Authorization': window.localStorage.getItem('TOKEN')
			},
			responseType: "blob"
		}).then(res => {
			const link = document.createElement("a");
			let blob = new Blob([res.data], {
				type: getFileType(data.fileType)
			});
			link.style.display = "none";
			link.href = URL.createObjectURL(blob);
			link.download = data.fileName //下载的文件名
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			resolve(true)
		}).catch(() => {
			resolve(false)
		})
	})
};
// 判断时间正确性。即结束时间不能小于开始时间
export const setTimePickerIsRight = (refValue, start, end) => {
    if (!refValue || !start || !end) {
        return;
    }
    const startFull = refValue[start].replace(/-/g,'/');
    const endFull = refValue[end].replace(/-/g,'/');
    if (new Date(endFull).getTime() < new Date(startFull).getTime()) {
        elMsg("结束时间不能小于开始时间", "warning");
        refValue[end] = refValue[start];
    }
}
// 使用天数
export const setUseTime = (a, b) => {
    if (!a || !b) {
        return '';
    } else {
        let start = new Date(a.replace(/-/ig,'/')).getTime();
        let end = new Date(b.replace(/-/ig,'/')).getTime();
        return '共' + Math.ceil((end - start) / 86400000) + '天';
    }
}
//时间转换函数,入参为时间间隔(毫秒),返回多少天，小时，分
export const timeIntervalToString = (time1, time2, showEmpty = false) => {
    if (typeof time1 == 'string') {
      time1 = time1.replace(/-/g, '/')
    }
    if (typeof time2 == 'string') {
      time2 = time2.replace(/-/g, '/')
    }
    var cha = new Date(time2).getTime() - new Date(time1);
    if (cha < 0) {
      return ''
    }
    var day = Math.floor(cha / (24 * 3600 * 1000));
    var hours = Math.floor(cha % (24 * 3600 * 1000) / (3600 * 1000));
    var minutes = Math.floor(cha % (24 * 3600 * 1000) % (3600 * 1000) / (60 * 1000));
    var retData = '';
    if (day != 0) {
      retData += day + '天';
    }else if(showEmpty){
      retData += '0天'
    }
    if (hours != 0) {
      retData += hours + '小时';
    }else if(showEmpty){
      retData += '0小时'
    }
    if (minutes != 0) {
      retData += minutes + '分';
    }else if(showEmpty){
      retData += '0分'
    }
    return retData;
  }
  // 根据小时和分钟数判断是否加班
export const getIsJiaban = () => {
	let h = new Date().getHours()
	let m = new Date().getMinutes()
	// 不在时间8点 — 17：30 的，都算为加班
	if(h > 17 || h < 8 || (h == 17 && m > 30)){
		return true
	}else{
		return false
	}
}
// 根据created_at和id模拟单号
export const createSnWithId = (id, created_at, backId) => {
    if (backId) {
        // 模拟单号返回真实ID
        return String(id).slice(8);
    } else if (id, created_at) {
        return created_at.slice(0,4) + created_at.slice(5,7) + created_at.slice(8,10) + id;
    } else {
        return ""
    }
}
// 获取二月的天数
export const getDaysInFebruary = (time) => {
    if (!time) {
        // 创建一个新的Date对象，日期设置为当前年份的3月1日
        const date = new Date(new Date().getFullYear(), 2, 0);
        // 获取当前年份2月的天数
        return date.getDate();
    } else {
        // 创建一个新的Date对象，日期设置为当前年份的3月1日
        const date = new Date(new Date(time.replace(/-/g,'/')).getFullYear(), 2, 0);
        // 获取当前年份2月的天数
        return date.getDate();
    }
}